import { IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import "./Footer.css";

const Footer: React.FC = () => {
  return (
    <div>
      <IonGrid style={{ background: "rgb(29 29 29)", color: "#fff" }}>
        <IonRow>
          <IonCol sizeMd="4" sizeSm="12" sizeXs="12">
            <article className="info">
              <h4>Company</h4>
              <ul>
                <li>
                  <a href="/about-us">About Us</a>
                </li>
                <li>
                  <a href="/privacy-policy">Privacy Policy</a>
                </li>
                <li>
                  <a href="/privacy-policy">Terms & Conditions</a>
                </li>
              </ul>
            </article>
          </IonCol>
          <IonCol sizeMd="4" sizeSm="12" sizeXs="12">
            <article className="info">
              <h4>Follow US</h4>
              <ul>
                <li style={{margin: "10px 0px"}}>
                  <a href="https://www.youtube.com/channel/UCOVaZH4xWxNqNRogOcaSZpQ">Youtube</a>
                </li>
                <li style={{margin: "10px 0px"}}>
                  <a href="https://www.facebook.com/MrBike-Rental-1382813625169352/?ti=as">Facebook</a>
                </li>
                <li style={{margin: "10px 0px"}}>
                  <a href="https://www.instagram.com/mrbikerentalsvizag?igsh=dHY2dXNxNWQybzNm">Instagram</a>
                </li>
              </ul>
            </article>
          </IonCol>
          <IonCol sizeMd="4" sizeSm="12" sizeXs="12">
            <article className="info">
              <h4>Contact US</h4>
              <ul>
                <li>
                  <p>
                    <strong>
                      <IonIcon name="time-outline"></IonIcon>
                    </strong>
                    &nbsp;&nbsp;6AM Open - 8PM Close
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      <IonIcon name="call-outline"></IonIcon>
                    </strong>
                    &nbsp;&nbsp;<a href="tel:+91 9155405060">+91 9155405060</a> | <a href="tel:+91 6301656193">+91 6301656193</a>
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      <IonIcon name="storefront-outline"></IonIcon>
                    </strong>
                    &nbsp;&nbsp;
                    <span>Gate No: 1, Railway Station, Vizag - 530004.</span>
                  </p>
                </li>
              </ul>
            </article>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonGrid style={{ background: "rgb(24 24 24)", color: "#fff" }}>
        <IonRow>
          <IonCol sizeMd="12" sizeSm="12" sizeXs="12">
            <article style={{ margin: "auto" }}>
              <p style={{ textAlign: "center", fontSize: "14px" }}>
                Copyright © 2017 -{" "}
                <a href="http://mrbikes.in">MrBikes{"  "}</a> - All rights
                reserved.
              </p>
            </article>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default Footer;
