import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonContent,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Home from "./pages/Home";
import WhatsApp from "./pages/WhatsApp";
import Location from "./pages/Location";
import Call from "./pages/Call";
import Privacy from './pages/Privacy';

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./App.css";
import { useRef } from "react";
import About from "./pages/About";

setupIonicReact();

const App: React.FC = () => {
  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;
  let isMobileDevice = regexp.test(details);
  const router = (
    <IonRouterOutlet>
      <Route exact path={"/privacy-policy"} component={Privacy} />
      <Route exact path={"/about-us"} component={About} />

      <Route exact path="/bikes-for-rental-visakapatnam">
        <Home />
      </Route>
      <Route exact path="/call-me-now" >
        <Call />
      </Route>
      <Route exact path="/ping-me-now">
        <WhatsApp />
      </Route>
      {/* <Route path="/pickup-location">
        <Location />
      </Route> */}
      <Route exact path="/">
        <Redirect to="/bikes-for-rental-visakapatnam" />
      </Route>
    </IonRouterOutlet>
  );
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop();
  };
  return (
    <IonApp>
      <IonContent ref={contentRef} scrollEvents={true}>
        <IonReactRouter>
          <IonTabs>
            {router}
            <IonTabBar
              style={{ height: "80px" }}
              slot={isMobileDevice ? "bottom" : "top"}
            >
              <IonTabButton
                tab="/bikes-for-rental-visakapatnam"
                href="/bikes-for-rental-visakapatnam"
                onClick={() => scrollToTop()}
              >
                <IonIcon
                  icon={"bicycle-outline"}
                  style={
                    isMobileDevice
                      ? { fontSize: "2.3rem", merginBottom: "5px" }
                      : {}
                  }
                />
                <IonLabel className="menu-size-increase">
                  {!isMobileDevice ? "Our Fleet" : "Bikes"}
                </IonLabel>
              </IonTabButton>
              <IonTabButton tab="call-me-now" onClick={() => window.location.href = "tel:+919155405060"}>
                <IonIcon
                  icon={"call-outline"}
                  style={
                    isMobileDevice
                      ? { fontSize: "2rem", merginBottom: "5px" }
                      : {}
                  }
                />
                <IonLabel className="menu-size-increase">
                  {isMobileDevice ? "Phone" : "Book via Call"}
                </IonLabel>
              </IonTabButton>
              <IonTabButton tab="ping-me-now" onClick={() => window.location.href = "https://wa.me/919155405060?text=Hi%20Mr%20Bikes"}>
                <IonIcon
                  icon={"logo-whatsapp"}
                  style={
                    isMobileDevice
                      ? { fontSize: "2rem", merginBottom: "5px" }
                      : {}
                  }
                />
                <IonLabel className="menu-size-increase">
                  {isMobileDevice ? "WhatsApp" : "Book via WhatsApp"}
                </IonLabel>
              </IonTabButton>
              <IonTabButton tab="pickup-location" onClick={() => window.location.href = "https://wa.me/919155405060?text=Share Me Pickup Location"}>
                <IonIcon
                  icon={"location-outline"}
                  style={
                    isMobileDevice
                      ? { fontSize: "2rem", merginBottom: "5px" }
                      : {}
                  }
                />
                <IonLabel className="menu-size-increase">Location</IonLabel>
              </IonTabButton>
              <IonTabButton
                style={isMobileDevice ? { display: "none" } : {}}
                href="https://www.facebook.com/MrBike-Rental-1382813625169352/?ti=as"
              >
                <IonIcon name="logo-facebook"></IonIcon>
                <IonLabel className="menu-size-increase">Facebook</IonLabel>
              </IonTabButton>
              <IonTabButton
                style={isMobileDevice ? { display: "none" } : {}}
                href="/call-me-now"
              >
                <div style={{ textAlign: "right" }}>
                  <IonLabel className="menu-size-increase color-call">
                    Reach Out On:{" "}
                  </IonLabel>
                  <br />
                  <IonLabel className="menu-size-increase color-call">
                    +91 9155 405060
                  </IonLabel>
                </div>
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
        </IonReactRouter>
      </IonContent>
    </IonApp>
  );
};

export default App;
